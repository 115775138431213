<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveUved">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>

                            </v-row>
                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" >
                                    <ValidationProvider ref="type_uved" rules="required|min:1"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('type_uved')}}</div>
                                        <v-select v-model="type_uved" :items="type_uvedItems"
                                                  hide-details
                                                  :error="!valid"
                                                  item-text="name" item-value="id"
                                                  :label="$t('type_uved')"
                                                  :error-messages="errors"
                                                  :disabled="loading"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                  clearable></v-select>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" v-if="type_uved === 1">
                                    <div class="input_label">{{$t('company_name')}}</div>
                                    <ValidationProvider ref="company_name" rules="required|min:2|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="company_name" type="text"
                                                      hide-details
                                                      :error="!valid"
                                                      :label="$t('company_name')"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6" v-if="type_uved === 2">
                                    <ValidationProvider ref="first_name" rules="required|min:2|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('first_name')}}</div>
                                        <v-text-field v-model="first_name" type="text"
                                                      hide-details
                                                      :error="!valid"
                                                      :label="$t('first_name')"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6" v-if="type_uved === 2">
                                    <ValidationProvider ref="last_name" rules="required|min:2|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('last_name')}}</div>
                                        <v-text-field v-model="last_name" type="text"
                                                      hide-details
                                                      :error="!valid"
                                                      :label="$t('last_name')"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6" v-if="type_uved === 2">
                                    <ValidationProvider ref="middle_name" rules="min:2|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('middle_name')}}</div>
                                        <v-text-field v-model="middle_name" type="text"
                                                      hide-details
                                                      :error="!valid"
                                                      :label="$t('middle_name')"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6" v-if="type_uved === 2">
                                    <ValidationProvider ref="sex" rules="min:1" v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('sex')}}</div>
                                        <v-select v-model="sex" :items="sexItems"
                                                  hide-details
                                                  :error="!valid"
                                                   item-text="name" item-value="id"
                                                  return-object
                                                  :label="$t('sex')"
                                                  :error-messages="errors"
                                                  :disabled="loading"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                  clearable></v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="type_client" rules="required|min:1"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('category_client')}}</div>
                                        <v-select v-model="type_client" :items="typeClientItems"
                                                  hide-details
                                                  :error="!valid"
                                                  item-text="name" item-value="id"
                                                  :label="$t('category_client')"
                                                  :error-messages="errors"
                                                  :disabled="loading"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                  clearable></v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col  cols="12" sm="6">
                                    <ValidationProvider ref="isResident" rules="required|min:1" v-slot="{ errors, valid }">
                                        <SelectInput
                                            id="is_resident"
                                            v-model="isResident"
                                            :items="[
                                                {
                                                    id: true,
                                                    name: $t('resident')
                                                },
                                                {
                                                    id: false,
                                                    name: $t('not_resident')
                                                }
                                            ]"
                                            hide-details
                                            :error="!valid"
                                            item-text="name"
                                            item-value="id"
                                            :label="$t('is_resident')"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            outlined
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                            clearable
                                        ></SelectInput>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <form-dialog :ref="'form_'+form.sort" v-for="form in forms" :key="form.sort" :form="form"  />
                                </v-col>


                                <v-col class="py-0" cols="12" v-for="(phone, i) in phones"
                                       :key="'add_phone_'+i">
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <ValidationProvider :ref="phone.phone_name + i" rules="required|min:3|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('phone')}}</div>
                                                <v-text-field v-model="phone.phone_value" type="text"
                                                              hide-details
                                                              :error="!valid"
                                                              :items="phone"
                                                              :item-value="phone.phone_value"
                                                              :label="$t('phone')"
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                              clearable>
                                                    <template v-if="phones.length > 1" v-slot:prepend>
                                                        <v-icon size="22" color="primary" class="input_icon" @click="deletePhone(i)"  >
                                                            mdi-delete
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <ValidationProvider :ref="phone.field_name + i" rules="min:1|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('comment')}}</div>
                                                <v-text-field v-model="phone.field_value" type="text"
                                                              hide-details
                                                              :error="!valid"
                                                              :items="phone"
                                                              :item-value="phone.field_value"
                                                              :label="$t('comment')"
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                              clearable>
                                                    <template v-slot:append-outer>
                                                        <v-icon size="22" color="primary" class="input_icon" @click="addPhone"  >
                                                            mdi-plus
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                </v-col>

                                <v-col class="py-0" cols="12" v-for="(address, i) in addresses"
                                       :key="'add_address_'+i">
                                    <v-row>
                                        <v-col cols="12" sm="9">
                                            <ValidationProvider :ref="address.address_name + i" rules="min:3|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('address')}}</div>
                                                <v-text-field v-model="address.address_value" type="text"
                                                              hide-details
                                                              :error="!valid"
                                                              :items="address"
                                                              :item-value="address.address_value"
                                                              :label="$t('address')"
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                              clearable>
                                                    <template v-if="addresses.length > 1" v-slot:prepend>
                                                        <v-icon size="22" color="primary" class="input_icon" @click="deleteAddress(i)"  >
                                                            mdi-delete
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <ValidationProvider :ref="address.office_name + i" rules="min:1|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('office')}}</div>
                                                <v-text-field v-model="address.office_value" type="text"
                                                              hide-details
                                                              :error="!valid"
                                                              :items="address"
                                                              :item-value="address.office_value"
                                                              :label="$t('office')"
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                              clearable>
                                                    <template v-slot:append-outer>
                                                        <v-icon size="22" color="primary" class="input_icon" @click="addAddress"  >
                                                            mdi-plus
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="6" v-for="(email, i) in emails"
                                       :key="'email_'+i">

                                    <ValidationProvider :ref="email.name + i" rules="min:3|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('email')}}</div>
                                        <v-text-field v-model="email.value" type="text"
                                                      hide-details
                                                      :prepend-icon="emails.length > 1 ? 'mdi-delete' : ''"
                                                      @click:prepend="deleteEmail(i)"
                                                      append-outer-icon="mdi-plus"
                                                      @click:append-outer="addEmail"
                                                      :error="!valid"
                                                      :items="email"
                                                      :item-value="email.value"
                                                      :label="$t('email')"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                            <template v-if="emails.length > 1" v-slot:prepend>
                                                <v-icon size="22" color="primary" class="input_icon" @click="deleteEmail(i)"  >
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <template v-slot:append-outer>
                                                <v-icon size="22" color="primary" class="input_icon" @click="addEmail"  >
                                                    mdi-plus
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="6" v-for="(site, i) in sites" :key="'site_'+i">
                                    <ValidationProvider
                                        :ref="site.url + i" rules="min:3|max:255"
                                        v-slot="{ errors, valid }"
                                    >
                                        <div class="input_label">{{$t('site')}}</div>
                                        <v-text-field
                                            v-model="site.url"
                                            type="text"
                                            hide-details
                                            :prepend-icon="sites.length > 1 ? 'mdi-delete' : ''"
                                            @click:prepend="deleteSite(i)"
                                            append-outer-icon="mdi-plus"
                                            @click:append-outer="addSite"
                                            :error="!valid"
                                            :items="site"
                                            :item-value="site.url"
                                            :label="$t('site')"
                                            :error-messages="errors"
                                            :disabled="loading"
                                            outlined
                                            solo
                                            flat
                                            dense
                                            color="primary"
                                            class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                            clearable
                                        >
                                            <template v-if="sites.length > 1" v-slot:prepend>
                                                <v-icon size="22" color="primary" class="input_icon" @click="deleteSite(i)"  >
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <template v-slot:append-outer>
                                                <v-icon size="22" color="primary" class="input_icon" @click="addSite"  >
                                                    mdi-plus
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="type_activity" rules="min:1" v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('type_activity')}}</div>
                                        <v-select v-model="type_activity"  :items="type_activityItems"
                                                  hide-details
                                                  :error="!valid"
                                                  item-text="name" item-value="id"
                                                  return-object
                                                  :label="$t('type_activity')"
                                                  :error-messages="errors"
                                                  :disabled="loading"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                  clearable></v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="company" rules="min:1"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('company')}}</div>
                                        <v-autocomplete v-model="company" :items="companyItems"
                                                        hide-details
                                                        :error="!valid"
                                                        :search-input.sync="companySearching"
                                                        item-text="name" item-value="id"
                                                        :loading="loadingCompanies"
                                                        :no-data-text="companySearching ? $t('nothing_found_by',{'search': companySearching}) : $t('nothing_found_name')"
                                                        :label="$t('company')"
                                                        @click:clear="companyItems=[]"
                                                        @click="clearCompanies"
                                                        autocomplete="off"
                                                        :error-messages="errors"
                                                        :disabled="loading"
                                                        outlined
                                                        solo
                                                        flat
                                                        dense
                                                        color="primary"
                                                        class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                        clearable>
                                            <template v-slot:item="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                            </template>
                                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                            </template>
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>




                                <v-col class="py-0" cols="12" sm="6">
                                    <v-dialog ref="dateDialog" v-model="dateDialog" width="290px">
                                        <v-date-picker ref="pickerDateContract" v-model="foundation_day"
                                                       :locale="lang"
                                                       @change="dateDialog = false">
                                        </v-date-picker>
                                    </v-dialog>
                                    <ValidationProvider ref="foundationDay" rules="date_format"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('foundation_day')}}</div>
                                        <v-text-field v-model="foundation_day"
                                                      hide-details
                                                      :error="!valid"
                                                      :label="$t('foundation_day')"
                                                      readonly
                                                      @click.stop="dateDialog = !dateDialog"
                                                       @click:clear="foundation_day = null"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="type_transport" rules="min:1" v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('type_transport')}}</div>
                                        <v-select v-model="type_transport" :items="type_transportItems"
                                                  hide-details
                                                  :error="!valid"
                                                   item-text="name" item-value="id"
                                                  return-object
                                                  :label="$t('type_transport')"
                                                  :error-messages="errors"
                                                  :disabled="loading"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                  clearable></v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="bin_inn" rules="min:12|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('bin_inn')}}</div>
                                        <v-text-field v-model="bin_inn" type="text"
                                                      hide-details
                                                      :error="!valid"
                                                      :label="$t('bin_inn')"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>



                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="supplier" rules="min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('supplier')}}</div>
                                        <v-text-field v-model="supplier" type="text"
                                                      hide-details
                                                      :error="!valid"
                                                      :label="$t('supplier')"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="products" rules="min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('products')}}</div>
                                        <v-text-field v-model="products" type="text"
                                                      hide-details
                                                      :error="!valid"
                                                      :label="$t('products')"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="client_code" rules="min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('client_code')}}</div>
                                        <v-text-field v-model="client_code" type="text"
                                                      hide-details
                                                      :error="!valid"
                                                      :label="$t('client_code')"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="supplier_address" rules="min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('supplier_address')}}</div>
                                        <v-text-field v-model="supplier_address" type="text"
                                                      hide-details
                                                      :error="!valid"
                                                      :label="$t('supplier_address')"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="tnved" rules="min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('tnved')}}</div>
                                        <v-text-field v-model="tnved" type="text"
                                                      hide-details
                                                      :error="!valid"
                                                      :label="$t('tnved')"
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" >
                                    <ValidationProvider ref="attraction_channel" rules="min:1" v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('attraction_channel')}}</div>
                                        <v-select v-model="attraction_channel" :items="attraction_channelItems"
                                                  hide-details
                                                  :error="!valid"
                                                   item-text="name" item-value="id"
                                                  return-object
                                                  :label="$t('attraction_channel')"
                                                  :error-messages="errors"
                                                  :disabled="loading"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                  clearable></v-select>
                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12">
                                    <ValidationProvider ref="note" rules="min:3|max:65535"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('note')}}</div>
                                        <v-textarea v-model="note" type="text" :error-messages="errors"
                                                    :disabled="loading" :label="$t('note')" rows="5"
                                                    color="primary"
                                                    auto-grow
                                                    clearable outlined
                                                    hide-details
                                                    full-width
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 input_textarea">
                                        </v-textarea>
                                    </ValidationProvider>
                                </v-col>

                            </v-row>
                        </v-card-text>
                        <v-card-actions class="buttons-block">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex";
    import debounce from "lodash/debounce";
    import FormDialog from '@/components/Eav/FormDialog.vue';
    import SelectInput from "@/components/Form/SelectInput.vue";

    export default {
        name: 'UvedForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            SelectInput,
            FormDialog
        },
        inject: ['forceRerender'],
        data() {
            return {
                dialogForm: true,
                heading: null,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                id: null,
                company_name: null,
                first_name: null,
                last_name: null,
                middle_name: null,
                type_uved: null,
                permissions: [],
                type_uvedItems: [
                    {
                        id: 1,
                        name: this.$t('company')
                    },
                    {
                        id: 2,
                        name: this.$t('individual')
                    }
                ],
                sex: null,
                sexItems: [],
                type_activity:null,
                type_activityItems: [],
                attraction_channel: null,
                attraction_channelItems: [],
                type_transport: null,
                type_transportItems: [],
                foundation_day: null,
                dateDialog: false,
                bin_inn: null,
                supplier: null,
                products: null,
                client_code: null,
                supplier_address: null,
                note: null,
                tnved: null,
                phones: [],
                addresses: [],
                emails: [],
                sites: [],
                company: null,
                companyItems: [],
                companySearching: null,
                loadingCompanies: false,
                type_client: null,
                typeClientItems: [],
                isResident: true,
                forms: [],
                uved_uuid: null,

            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        watch: {
            companySearching: debounce(function (val) {
                if (val && !this.company) {
                    this.getCompanies(val)
                }
            }, 500)
        },
        async mounted() {
            this.language = this.languages[this.tab]
            this.checkCreate()
        },
        methods: {
            async   checkCreate() {

                this.getSexes();
                this.getTypeClients();
                this.getTypeActivities();
                this.getAttractionChannels();
                this.getTypeTransports();
                if (this.$route.name === "uved.create") {
                    this.addEmail();
                    this.addSite();
                    this.addPhone();
                    this.addAddress();
                    this.heading = this.$t('uved_creation')
                } else {
                    this.heading = this.$t('uved_editing')
                    if (this.$route.params.id) {
                        await this.getUved()
                        await this.getGroups()
                    }
                }
            },
            async getGroups(){

                this.loading = true;
                let params = {};

                params.type_form = 'groups';
                params.entity_type = 'uved';
                params.sub_entity_type = 'type_client';
                params.sub_entity_id = this.type_client;


                if(this.uved_uuid){
                    params.entity_uuid = this.uved_uuid;
                }
                await this.$http
                    .get(`admin/dynamic_form`, {
                        params: params,
                    })
                    .then(res => {
                        if (res?.body?.data?.[0]?.id) {
                            this.forms = res.body.data;
                        }
                        else{
                            this.forms = [];
                        }
                    })
                    .catch(err => {
                        this.forms = [];
                        this.$toastr.error(this.$t('failed_to_get_list_groups'))
                    })
                    .finally(end => {
                        this.loading = false
                    })

            },
            closeDialogAdd(){
                this.$router.push({
                    name: 'uved',
                })
            },
            async getTypeClients() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/type_client", {
                        params: params,
                    })
                    .then(res => {
                        this.typeClientItems = res.body.data.filter((typeClient) => {
                            return typeClient.permissions.filter((permission) => {
                                return permission.permission.name === 'uved.edit'
                            }).length > 0
                        });
                    })
                    .catch(err => {
                        this.typeClientItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getSexes() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/sex", {
                        params: params,
                    })
                    .then(res => {
                        this.sexItems = res.body.data
                    })
                    .catch(err => {
                        this.sexItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getTypeTransports() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/type_transport", {
                        params: params,
                    })
                    .then(res => {
                        this.type_transportItems = res.body.data
                    })
                    .catch(err => {
                        this.type_transportItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getTypeActivities() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/type_activity", {
                        params: params,
                    })
                    .then(res => {
                        this.type_activityItems = res.body.data
                    })
                    .catch(err => {
                        this.type_activityItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getAttractionChannels() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/attraction_channel", {
                        params: params,
                    })
                    .then(res => {
                        this.attraction_channelItems = res.body.data
                    })
                    .catch(err => {
                        this.attraction_channelItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            clearCompanies() {
                if (!this.company) {
                    this.companyItems = []
                }
            },
            redirectToCompany(val) {
                if (val) {
                    this.$router.push({
                        name: 'company.profile',
                        params: {
                            id: val
                        }
                    })
                }
            },
            async getCompanies(str) {
                if (str) {
                    this.loadingCompanies = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.company = str
                    }
                    await this.$http
                        .get("admin/company", {
                            params: params,
                        })
                        .then(res => {
                            this.companyItems = res.body.data
                        })
                        .catch(err => {
                            this.companyItems = []
                        })
                        .finally(end => {
                            this.loadingCompanies = false
                        })
                }
            },

            addPhone(){
                this.phones.push(
                    {
                        phone_name: 'phone' + (this.phones.length + 1),
                        phone_value: '',
                        field_name: 'field' + (this.phones.length + 1),
                        field_value: '',
                    })
            },
            deletePhone(i){
                this.phones.splice(i, 1)
            },
            addAddress(){
                this.addresses.push(
                    {
                        address_name: 'address' + (this.addresses.length + 1),
                        address_value: '',
                        office_name: 'office' + (this.addresses.length + 1),
                        office_value: '',
                    })
            },
            deleteAddress(i){
                this.addresses.splice(i, 1)
            },
            addEmail(){
                this.emails.push(
                    {
                        name: 'email' + (this.addresses.length + 1),
                        value: '',
                    })
            },
            deleteEmail(i){
                this.emails.splice(i, 1)
            },
            addSite(){
                this.sites.push({
                    url: '',
                })
            },
            deleteSite(i){
                this.sites.splice(i, 1)
            },
            async getUved() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/uved/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {

                        const phones_db = res.body.data.phones;
                        phones_db.forEach((element, key) => {
                            this.phones.push(
                                {
                                    phone_name: 'phone' + key,
                                    phone_value: element.phone,
                                    field_name: 'field' + key,
                                    field_value: element.field,
                                });

                        });

                        const addresses_db = res.body.data.addresses;
                        addresses_db.forEach((element, key) => {
                            this.addresses.push(
                                {
                                    address_name: 'address' + key,
                                    address_value: element.address,
                                    office_name: 'office' + key,
                                    office_value: element.field,
                                });

                        });

                        const emails_db = res.body.data.emails;
                        emails_db.forEach((element, key) => {
                            this.emails.push(
                                {
                                    name: 'email' + key,
                                    value: element.email,
                                });

                        });

                        let company = res.body.data.company
                        if (company && company.id) {
                            this.company = company.id
                            this.companyItems = [res.body.data.company]
                        } else {
                            this.companyItems = []
                        }
                        this.uved_uuid = res.body.data.uuid
                        this.type_uved = res.body.data.permissions
                        this.type_uved = res.body.data.type_uved
                        this.company_name = res.body.data.company_name
                        this.first_name = res.body.data.first_name
                        this.last_name = res.body.data.last_name
                        this.middle_name = res.body.data.middle_name
                        this.company = res.body.data.company.id
                        this.sex = res.body.data.sex.id
                        this.type_client = res.body.data.type_client.id
                        this.type_activity = (res.body.data.type_activity && res.body.data.type_activity.id) ? res.body.data.type_activity.id : null
                        this.foundation_day = res.body.data.foundation_day
                        this.attraction_channel = (res.body.data.attraction_channel && res.body.data.attraction_channel.id) ? res.body.data.attraction_channel.id : null
                        this.bin_inn = res.body.data.bin_inn
                        this.supplier = res.body.data.supplier
                        this.products = res.body.data.products
                        this.client_code = res.body.data.client_code
                        this.note = res.body.data.note
                        this.supplier_address = res.body.data.supplier_address
                        this.type_transport = (res.body.data.type_transport && res.body.data.type_transport.id) ? res.body.data.type_transport.id : null
                        this.tnved = res.body.data.tnved


                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_uved'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveUved() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }

                this.forms.reduce((acc, item) => {
                    const fields = item.fields;
                    const group_id = item.id
                    for (let fieldName in fields) {
                        if (Object.hasOwn(fields, fieldName)) {
                            formData.append(`fields[${group_id}][${fieldName}]`,fields[fieldName].default)
                        }
                    }
                }, []);

                if (this.company) {
                    if (this.company.id) {
                        formData.append('company', this.company.id)
                    } else {
                        formData.append('company', this.company)
                    }
                }

                this.phones.forEach(function callback(item, key) {
                    formData.append(`phones[${key}][phone]`, item.phone_value)
                    formData.append(`phones[${key}][field]`, item.field_value)
                });

                this.addresses.forEach(function callback(item, key) {
                    formData.append(`addresses[${key}][address]`, item.address_value)
                    formData.append(`addresses[${key}][office]`, item.office_value)
                });

                this.emails.forEach(function callback(item, key) {
                    formData.append(`emails[${key}]`, item.value)
                });

                this.sites.forEach(function callback(item, key) {
                    if(item.url) {
                        formData.append(`sites[${key}]`, item.url)
                    }
                });

                if (this.company_name) {
                    formData.append('company_name', this.company_name)
                }
                if (this.first_name) {
                    formData.append('first_name', this.first_name)
                }
                if (this.last_name) {
                    formData.append('last_name', this.last_name)
                }
                if (this.middle_name) {
                    formData.append('middle_name', this.middle_name)
                }
                if (this.foundation_day) {
                    formData.append('foundation_day', this.foundation_day)
                }
                if (this.bin_inn) {
                    formData.append('bin_inn', this.bin_inn)
                }
                if (this.supplier) {
                    formData.append('supplier', this.supplier)
                }
                if (this.products) {
                    formData.append('products', this.products)
                }
                if (this.client_code) {
                    formData.append('client_code', this.client_code)
                }
                if (this.note) {
                    formData.append('note', this.note)
                }
                if (this.supplier_address) {
                    formData.append('supplier_address', this.supplier_address)
                }
                if (this.tnved) {
                    formData.append('tnved', this.tnved)
                }

                if (this.type_transport) {
                    if (this.type_transport.id) {
                        formData.append('type_transport', this.type_transport.id)
                    } else {
                        formData.append('type_transport', this.type_transport)
                    }
                }

                if (this.attraction_channel) {
                    if (this.attraction_channel.id) {
                        formData.append('attraction_channel', this.attraction_channel.id)
                    } else {
                        formData.append('attraction_channel', this.attraction_channel)
                    }
                }

                if (this.type_activity) {
                    if (this.type_activity.id) {
                        formData.append('type_activity', this.type_activity.id)
                    } else {
                        formData.append('type_activity', this.type_activity)
                    }
                }

                if (this.sex) {
                    if (this.sex.id) {
                        formData.append('sex', this.sex.id)
                    } else {
                        formData.append('sex', this.sex)
                    }
                }
                if (this.type_client) {
                    if (this.type_client.id) {
                        formData.append('type_client', this.type_client.id)
                    } else {
                        formData.append('type_client', this.type_client)
                    }
                }
                if (this.type_uved) {
                    if (this.type_uved.id) {
                        formData.append('type_uved', this.type_uved.id)
                    } else {
                        formData.append('type_uved', this.type_uved)
                    }
                }

                formData.append('is_resident', this.isResident ? 1 : 0);

                if (this.$route.params.id) {
                    await this.$http
                        .put(`admin/uved/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('uved_has_been_updated'))
                            // if (res && res.body && res.body.data ) {
                            //
                            // }
                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('uved_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/uved', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('uved_has_been_added'))
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.$router.push({
                                    name: 'uved.show',
                                    params: {
                                        id: res.body.data.id
                                    }
                                })
                            } else {
                                this.$router.push({
                                    name: 'uved'
                                })
                            }
                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('uved_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
